import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroMessage from "../components/heroMessage/heroMessage"
import AcademicProgramsSection from "../components/academicPrograms/academicPrograms"
import OurPurpose from "../components/ourPurpose/ourPurpose"
import UpcomingEvents from "../components/upcomingEvents/upcomingEvents"
import NewsSection from "../components/news/NewsSection"
import SubscribeSection from "../components/subscribe/subscribeSection"

const IndexPage = () => {
  return (
    <React.Fragment>
      <SEO title="Belajar Desain Komunikasi Visual" />
      <Layout>
        <HeroMessage />
        <AcademicProgramsSection />
        <OurPurpose />
        <UpcomingEvents />
        <NewsSection />
        <SubscribeSection />
      </Layout>
    </React.Fragment>
  )
}
export default IndexPage
