import React, { useState } from "react"
import Img from "gatsby-image"
import { graphql, useStaticQuery, Link } from "gatsby"

const AcademicProgramsSection = () => {
  const {
    komunikasiGrafis,
    komunikasiMultimedia,
    komunikasiPeriklanan,
  } = useStaticQuery(graphql`
    {
      komunikasiGrafis: file(
        relativePath: {
          eq: "placeholder/academic-programs/komunikasi-grafis.png"
        }
      ) {
        childImageSharp {
          fluid(duotone: { highlight: "#86B3E3", shadow: "#1E5591" }) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      komunikasiMultimedia: file(
        relativePath: {
          eq: "placeholder/academic-programs/komunikasi-multimedia.png"
        }
      ) {
        childImageSharp {
          fluid(duotone: { highlight: "#86B3E3", shadow: "#1E5591" }) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      komunikasiPeriklanan: file(
        relativePath: {
          eq: "placeholder/academic-programs/komunikasi-periklanan.png"
        }
      ) {
        childImageSharp {
          fluid(duotone: { highlight: "#86B3E3", shadow: "#1E5591" }) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <React.Fragment>
      <div className="container py-5">
        <div className="my-5" />
        <div className="row">
          <div className="col-12 col-md-4 mt-4 mt-md-0">
            <Link
              to="/acadmemic-programs/komunikasi-grafis"
              className="text-decoration-none"
            >
              <div
                className="position-relative w-100"
                style={{ paddingTop: "75%" }}
              >
                <Img
                  className="position-absolute w-100 rounded"
                  style={{ top: "0", left: "0", height: "100%" }}
                  fluid={komunikasiGrafis.childImageSharp.fluid}
                />
                <div
                  className="position-absolute w-100 d-flex justify-content-center align-items-center container text-light"
                  style={{ top: "0", left: "0", right: "0", bottom: "0" }}
                >
                  <h3 className="text-center">Komunikasi Grafis</h3>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-12 col-md-4 mt-4 mt-md-0">
            <Link
              to="/acadmemic-programs/komunikasi-multimedia"
              className="text-decoration-none"
            >
              <div
                className="position-relative w-100"
                style={{ paddingTop: "75%" }}
              >
                <Img
                  className="position-absolute w-100 rounded"
                  style={{ top: "0", left: "0", height: "100%" }}
                  fluid={komunikasiMultimedia.childImageSharp.fluid}
                />
                <div
                  className="position-absolute w-100 d-flex justify-content-center align-items-center container text-light"
                  style={{ top: "0", left: "0", right: "0", bottom: "0" }}
                >
                  <h3 className="text-center">Komunikasi Multimedia</h3>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-12 col-md-4 mt-4 mt-md-0">
            <Link
              to="/acadmemic-programs/komunikasi-periklanan"
              className="text-decoration-none"
            >
              <div
                className="position-relative w-100"
                style={{ paddingTop: "75%" }}
              >
                <Img
                  className="position-absolute w-100 rounded"
                  style={{ top: "0", left: "0", height: "100%" }}
                  fluid={komunikasiPeriklanan.childImageSharp.fluid}
                />
                <div
                  className="position-absolute w-100 d-flex justify-content-center align-items-center container text-light"
                  style={{ top: "0", left: "0", right: "0", bottom: "0" }}
                >
                  <h3 className="text-center">Komunikasi Periklanan</h3>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className="my-5" />
      </div>
    </React.Fragment>
  )
}

export default AcademicProgramsSection
