import React from "react"

const OurPurposeSection = () => {
  return (
    <React.Fragment>
      <div className="container py-5">
        <div className="my-5" />
        <h2
          className="text-center h1 text-uppercase"
          style={{ color: "#1E5591" }}
        >
          Our Purpose
        </h2>
        <div className="my-3" />
        <p
          className="text-center mx-auto text-dark"
          style={{ maxWidth: "650px" }}
        >
          Menyalurkan kemampuan utama berupa kecerdasan kreatif dalam
          mengantisipasi dan memecahkan aneka masalah bidang komunikasi visual
          dengan penekanan pada sisi invensi dan inovasi pribadi yang
          profesional dan berlandaskan budaya rupa nusantara.
        </p>
        <div className="my-5" />
      </div>
    </React.Fragment>
  )
}

export default OurPurposeSection
