import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const HeroMessage = () => {
  const { heroImage } = useStaticQuery(graphql`
    {
      heroImage: file(
        relativePath: { eq: "placeholder/hero-image/hero-image.png" }
      ) {
        childImageSharp {
          fluid(
            duotone: { highlight: "#86B3E3", shadow: "#1E5591" }
            quality: 100
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <React.Fragment>
      <div className="position-relative w-100 hero-image">
        <Img
          className="position-absolute w-100"
          style={{ top: "0", left: "0", height: "100%" }}
          fluid={heroImage.childImageSharp.fluid}
        />
        <div className="hero-message container">
          <h1 className="text-uppercase">
            The Future Leaders In Creative Economy Era
          </h1>
        </div>
      </div>
    </React.Fragment>
  )
}

export default HeroMessage
