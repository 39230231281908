import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

const NewsSection = () => {
  const { news } = useStaticQuery(graphql`
    {
      news: allFile(filter: { relativeDirectory: { eq: "placeholder/news" } }) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  return (
    <React.Fragment>
      <div className="container py-5">
        <div className="my-5" />
        <h2
          className="text-center h1 text-uppercase"
          style={{ color: "#1E5591" }}
        >
          News
        </h2>
        <div className="my-5" />
        <div className="row">
          {news.edges.map(({ node }) => {
            return (
              <div key={`${node.id}`} className="col-12 col-md-4 mt-4 mt-md-0">
                <Link
                  className="text-decoration-none"
                  to={`/news-opportunities/${node.name
                    .toString()
                    .trim()
                    .toLowerCase()
                    .replace(/\s+/g, "-")
                    .replace(/[^\w\-]+/g, "")
                    .replace(/\-\-+/g, "-")
                    .replace(/^-+/, "")
                    .replace(/-+$/, "")}`}
                >
                  <div
                    className="position-relative w-100"
                    style={{ paddingTop: "75%" }}
                  >
                    <Img
                      className="position-absolute w-100 rounded"
                      style={{ top: "0", left: "0", height: "100%" }}
                      fluid={node.childImageSharp.fluid}
                    />
                  </div>
                  <div className="my-3" />
                  <h4 className="text-dark">{node.name}</h4>
                </Link>
              </div>
            )
          })}
        </div>
        <div className="my-5" />
      </div>
    </React.Fragment>
  )
}

export default NewsSection
