import React, { useState } from "react"

const SubscribeSection = () => {
  const [value, setValue] = useState("")
  const [message, setMessage] = useState()
  function onSubmit(e) {
    e.preventDefault()
    setMessage(
      <p className="small text-success text-center">
        Success, We've received your {value}
      </p>
    )
  }
  return (
    <React.Fragment>
      <div className="container py-5">
        <div className="my-5" />
        <h2 className="text-center h1" style={{ color: "#1E5591" }}>
          Subscribe
        </h2>
        <p className="text-center">
          Submit your email to receive monthly update
        </p>
        <div className="my-5" />
        <form onSubmit={onSubmit}>
          <div className="row justify-content-center">
            <div className="col-12 col-md-4 form-group">
              <input
                className="form-control"
                placeholder="Your Email"
                type="email"
                value={value}
                onChange={e => setValue(e.target.value)}
              />
              <small className="form-text text-muted">
                Kami tidak akan membagikan email anda ke siapapun.
              </small>
            </div>
            <div className="col-12 col-md-4">
              <button
                className="btn btn-primary w-100"
                type="submit"
                style={{ backgroundColor: "#1E5591" }}
              >
                Subscribe
              </button>
            </div>
          </div>
        </form>
        {message}
        <div className="my-5" />
      </div>
    </React.Fragment>
  )
}
export default SubscribeSection
