import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const UpcomingEvents = () => {
  const { upcomingEvents } = useStaticQuery(graphql`
    {
      upcomingEvents: allFile(
        filter: { relativeDirectory: { eq: "placeholder/upcoming-events" } }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  return (
    <React.Fragment>
      <div className="container py-5">
        <div className="my-5" />
        <h2
          className="text-center h1 text-uppercase"
          style={{ color: "#1E5591" }}
        >
          Upcoming Events
        </h2>
        <div className="my-5" />
        <div className="row">
          {upcomingEvents.edges.map(({ node }) => {
            return (
              <div key={`${node.id}`} className="col-12 col-md-4 mt-4 mt-md-0">
                <Link
                  to={`/upcoming-events/${node.name}`}
                  className="text-decoration-none"
                >
                  <div
                    className="position-relative w-100"
                    style={{ paddingTop: "100%" }}
                  >
                    <Img
                      className="position-absolute w-100 rounded"
                      style={{ top: "0", left: "0" }}
                      fluid={node.childImageSharp.fluid}
                    />
                  </div>
                </Link>
              </div>
            )
          })}
        </div>
        <div className="my-5" />
      </div>
    </React.Fragment>
  )
}

export default UpcomingEvents
